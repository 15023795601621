<template>
  <q-layout view="hHh lpR fFf">
    <q-header elevated class="bg-primary text-white">
      <q-toolbar style="height: 64px;">
        <img alt="logo" src="./assets/logo.svg" style="max-width: 40px">
        <q-toolbar-title style="font-size: 2.1rem; line-height: 64px; font-weight: normal;">
          Digitaal leren
        </q-toolbar-title>
      </q-toolbar>
    </q-header>
    <q-page-container>
      <suspense>
        <router-view />
      </suspense>
    </q-page-container>
  </q-layout>
</template>

<script lang="ts"></script>
