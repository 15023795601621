import client from '@/domains/api/client'
import { AxiosPromise } from 'axios'

export interface ILessons {
  id: number
  title: string
  uri: string
  eancode: string
  visibility: {
    teacher: boolean
    student: boolean
  }
}

export interface ICourse {
  id: number
  title: string
  eancode: string
  lessons: ILessons[]
}

export default class Api {
  public static readCourses = (idToken: string | undefined): AxiosPromise<ICourse[]> => {
    if (idToken) return client.get<ICourse[]>(`/courses?id_token=${idToken}`)
    return client.get<ICourse[]>('/courses')
  }
}
