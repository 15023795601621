
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Links',
  props: ['lesson'],
  setup () {
    function redirect (v: string): void {
      window.location.replace(v)
    }

    return {
      redirect
    }
  }
})
