import { render } from "./Links.vue?vue&type=template&id=7b13371c"
import script from "./Links.vue?vue&type=script&lang=ts"
export * from "./Links.vue?vue&type=script&lang=ts"
script.render = render

export default script
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';import Ripple from 'quasar/src/directives/Ripple.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QItem,QItemSection,QItemLabel});qInstall(script, 'directives', {Ripple});
