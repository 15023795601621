import { render } from "./Licenses.vue?vue&type=template&id=7355156a&scoped=true"
import script from "./Licenses.vue?vue&type=script&lang=ts"
export * from "./Licenses.vue?vue&type=script&lang=ts"

import "./Licenses.vue?vue&type=style&index=0&id=7355156a&scoped=true&lang=css"
script.render = render
script.__scopeId = "data-v-7355156a"

export default script
import QCard from 'quasar/src/components/card/QCard.js';
import QList from 'quasar/src/components/item/QList.js';
import QBanner from 'quasar/src/components/banner/QBanner.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QCard,QList,QBanner});
