import { render } from "./App.vue?vue&type=template&id=b7f13a8e"
const script = {}
script.render = render

export default script
import QLayout from 'quasar/src/components/layout/QLayout.js';
import QHeader from 'quasar/src/components/header/QHeader.js';
import QToolbar from 'quasar/src/components/toolbar/QToolbar.js';
import QToolbarTitle from 'quasar/src/components/toolbar/QToolbarTitle.js';
import QPageContainer from 'quasar/src/components/page/QPageContainer.js';
import qInstall from "../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QLayout,QHeader,QToolbar,QToolbarTitle,QPageContainer});
