<template>
  <div class="home q-px-lg row justify-center">
    <div class="col-10">
      <h2>Cursussen</h2>
      <p>
        Selecteer een cursusonderdeel om door te gaan.
      </p>
      <q-card v-for="course in courses" :key="course.id" class="card full-width">
        <div class="header bg-primary text-white full-width q-pa-md">
          {{ course.title }}
        </div>
        <div v-if="course.lessons.length === 0" class="q-pa-md">
          Er zijn geen beschikbare lessen voor dit lesmateriaal.
        </div>
        <div v-else class="q-pa-md">
          <q-list>
            <links v-for="lesson in filterAndSort(course.lessons)" :key="lesson.id" :lesson="lesson" />
          </q-list>
        </div>
      </q-card>
      <q-banner v-if="courses.length === 0" inline-actions class="text-white bg-red">
        Op basis van het EAN konden geen lessen worden gevonden.
      </q-banner>
    </div>
  </div>
</template>

<script lang="ts">
import Links from '@/components/Links.vue'
import Api, { ICourse, ILessons } from '@/domains/api'
import { defineComponent } from 'vue'
import { useRoute } from 'vue-router'

export default defineComponent({
  name: 'Licences',
  components: { Links },
  async setup () {
    const route = useRoute()
    const idToken = route.query?.id_token ? String(route.query.id_token) : undefined
    const courses: ICourse[] | undefined = (await Api.readCourses(idToken)).data
      .filter((v): boolean => v.eancode === route.params.ean)
    function compare (a: ILessons, b: ILessons) {
      if (a.title < b.title) return -1
      return a.title > b.title ? 1 : 0
    }

    function filterAndSort (v: ILessons[]): ILessons[] {
      v.sort(compare)
      return v.filter((v) => (v.visibility.student || v.visibility.teacher))
    }

    return {
      courses,
      filterAndSort
    }
  }
})
</script>

<style scoped>
h2 {
  border-bottom: 1px solid #e0e0e0;
  font-size: 1.8rem;
}
</style>
