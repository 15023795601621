<template>
  <div class="full-width row">
    <q-item clickable v-ripple class="col-12" @click="redirect(lesson.uri)">
      <q-item-section avatar>
        <img src="https://images.edu-access.nl/small/9789054511465" style="max-height: 40px;" />
      </q-item-section>
      <q-item-section class="q-pl-md" style="font-size: 1.1rem">
        {{ lesson.title }}
      </q-item-section>
      <q-item-section side>
        <q-item-label caption>
          {{ lesson.eancode }}
        </q-item-label>
      </q-item-section>
    </q-item>
  </div>
</template>

<style>
</style>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Links',
  props: ['lesson'],
  setup () {
    function redirect (v: string): void {
      window.location.replace(v)
    }

    return {
      redirect
    }
  }
})
</script>
