
import Links from '@/components/Links.vue'
import Api, { ICourse, ILessons } from '@/domains/api'
import { defineComponent } from 'vue'
import { useRoute } from 'vue-router'

export default defineComponent({
  name: 'Licences',
  components: { Links },
  async setup () {
    const route = useRoute()
    const idToken = route.query?.id_token ? String(route.query.id_token) : undefined
    const courses: ICourse[] | undefined = (await Api.readCourses(idToken)).data
      .filter((v): boolean => v.eancode === route.params.ean)
    function compare (a: ILessons, b: ILessons) {
      if (a.title < b.title) return -1
      return a.title > b.title ? 1 : 0
    }

    function filterAndSort (v: ILessons[]): ILessons[] {
      v.sort(compare)
      return v.filter((v) => (v.visibility.student || v.visibility.teacher))
    }

    return {
      courses,
      filterAndSort
    }
  }
})
