import axios, { AxiosInstance } from 'axios'
import {
  installCacheHandler,
  installErrorHandler,
  installImmutableHandler
} from '@ultraware/axios-handlers'

const client: AxiosInstance = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  timeout: 5000,
  headers: {
    'Content-Type': 'application/json'
  },
  withCredentials: false
})

installCacheHandler(client)
installImmutableHandler(client)
export const axiosErrorHandler = installErrorHandler(client)
export default client
